import { Component, OnInit, Input } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { AuthService } from "../../auth.service";
import { RouterLink, Router } from "@angular/router";
import { MetadataService } from "../../MetadataService";
import { BaseService } from "src/app/BaseService";
import { MustMatch } from "src/helpers/must-match.validator";
import { DataService } from "src/app/dataService";
import { SocialAuthService } from "src/app/social-auth.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  message: string;
  metadata: any;
  signup: boolean;
  context = "company";
  showLoading = true;
  showSignupForm = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private metadataService: MetadataService,
    private socialAuthentication: SocialAuthService,
    private socialAuth: SocialAuthService
  ) {}

  ngOnInit() {
    this.message = "";
    this.signup = false;
    this.signup = localStorage.getItem("uuid") != null;

    this.createSignUpForm(this.context);
  }

  // signUp form for company and candidate
  createSignUpForm(context) {
    let isCompany = context == "company";

    this.signupForm = this.formBuilder.group(
      {
        name: new FormControl(
          "",
          isCompany ? [Validators.required, Validators.maxLength(50)] : []
        ),
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(255),
        ]),
        confirm: new FormControl("", [Validators.required]),
      },
      {
        validator: MustMatch("password", "confirm"),
      }
    );
  }

  //for toggle radio button
  //update context value
  onChange(context) {
    this.createSignUpForm(context);
  }

  get formControls() {
    return this.signupForm.controls;
  }

  signUpCandidate() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }

    if (this.context === "candidate") {
      this.authService.signupCandidate(this.signupForm.value).subscribe(
        (user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('ACCESS_TOKEN', JSON.stringify(user));
          // localStorage.setItem('uuid', user.uuid);
          // localStorage.setItem('context', 'candidate');

          this.metadataService.getMetaData().subscribe((data: any) => {
            this.metadata = data;
            $("#SignUpModal").modal("hide");
            this.router.navigate(["candidates/complete-profile"]);
          });
        },
        (error) => {
          console.log(error.error.message);
          this.message = error.error.message;

          if (this.message == "Email already exists.") {
            this.message = "Αυτό το email υπάρχει ήδη!";
          }
        }
      );
      if (this.signupForm) {
        this.signupForm.reset();
        this.signupForm.markAsUntouched();
        this.submitted = false;
        this.message = "";
      }
    }
    // else {
    //   this.authService.signupEmployer(this.signupForm.value)
    //     .subscribe(user => {
    //       // store user details and jwt token in local storage to keep user logged in between page refreshes
    //       localStorage.setItem('uuid', user.uuid);
    //       localStorage.setItem('context', 'company');
    //       this.authService.signedName = user.name;
    //       this.metadataService.getMetaData().subscribe((data: any) => {
    //         this.metadata = data;
    //         $("#SignUpModal").modal("hide");
    //         this.router.navigate(['employers/complete-profile']);
    //       });
    //     },
    //       error => {
    //         console.log(error.error.message);
    //         this.message = error.error.message;

    //         if (this.message == "Email already exists.") {
    //           this.message = "Αυτό το email υπάρχει ήδη!"
    //         }
    //       });
    //   if (this.signupForm) {
    //     this.signupForm.reset();
    //     this.signupForm.markAsUntouched();
    //     this.submitted = false;
    //     this.message = "";
    //   }
    // }
  }

  signInWithFacebookOrGoogle(provider) {
    $("#SignUpModal").modal("hide");
    this.socialAuth.signInWithSocial(provider);
  }

  goToGemi() {
    this.authService.getGemiAuthUrlandRedirectToGemi();
  }

  goToLinkedIn() {
    this.authService.getLinkedInAuthUrlAndRedirectToLinkedIn();
  }

  cancel() {
    this.signup = false;
    this.message = "";

    if (this.signupForm) {
      this.signupForm.reset();
      this.signupForm.markAsUntouched();
      this.submitted = false;
      this.message = "";
    }
    this.router.navigate(["candidates"]);
  }
}
