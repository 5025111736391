import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService } from "./BaseService";
import { Observable, Subject, BehaviorSubject, pipe } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Content } from "@angular/compiler/src/render3/r3_ast";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService extends BaseService {
  //authenticated: has a boolean value that shows if candidate or company user is logged in(Not for the admin)
  authenticated: BehaviorSubject<boolean> = new BehaviorSubject(
    !!localStorage.getItem("uuid")
  );
  //isAuthenticated$: the observale of our previous subject
  isAuthenticated$ = this.authenticated.asObservable();

  adminAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(
    !!localStorage.getItem("adminToken")
  );
  isAdminAuthenticated$ = this.adminAuthenticated.asObservable();

  /*isValidCompany and isValidCandidate: are boolean variables stored in the database thats informs us
    if the profile of a user is completed. This affects UI by changing the header profile tab options(*ngIf in the header component)
    Their values depends on the completion or not of some fields in their complete-profile form
    Lastly the value of a user when he's signed up is false in the database. He is navigated to the form so if he completes all the nessesary data
    the varible takes true or else it remains false. So in every log in or refresh in the angular front-end we fetch that information and store it to the variable
    to give the user the options that we want*/
  isValidCompany = false;
  isValidCandidate = false;
  /*signedName: is a string variable contains the name of the candidate or the company user that displays on header. 
    The name is fetched after a successful login of a candidate or a company and stored in this value*/
  signedName = "";
  /*isAdmin: is a boolean variable that takes true inside app-component.js navigation event hooks for admin components routes so to display the admin-header and admin-footer*/
  isAdmin = false;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  // a authentication function that returns a pipe to run after a successful login request
  private setAuthenticated() {
    return pipe(
      tap((user: any) => {
        localStorage.setItem("uuid", user.uuid);
        localStorage.setItem("context", user.context);
        localStorage.setItem("token", user.accessToken);
        localStorage.setItem("refreshToken", user.refreshToken);
        localStorage.setItem("lastSuccessfulReqTime", new Date().toString());
      }),
      //subject emits true now
      tap(() => this.authenticated.next(true))
    );
  }

  private setCompanyAuthenticated() {
    return pipe(
      tap((user: any) => {
        localStorage.setItem("id", user.id);
        localStorage.setItem("context", user.context);
        localStorage.setItem("token", user.accessToken);
        localStorage.setItem("refreshToken", user.refreshToken);
        localStorage.setItem("lastSuccessfulReqTime", new Date().toString());
      }),
      //subject emits true now
      tap(() => this.authenticated.next(true))
    );
  }

  // a authentication function that returns a pipe to run after a successful admin! login request
  private setAdminAuthenticated() {
    return pipe(
      tap((admin: any) => {
        localStorage.setItem("adminUuid", admin.uuid);
        localStorage.setItem("adminContext", admin.context);
        localStorage.setItem("adminToken", admin.accessToken);
        localStorage.setItem("adminRefreshToken", admin.refreshToken);
        localStorage.setItem("lastSuccessfulReqTime", new Date().toString());
        this.adminAuthenticated.next(true);
      })
    );
  }

  // login/social_login and signup for candidate requests
  login(userInfo: any) {
    return this.http.post<any>(`${this.apiEndPoint}/login`, userInfo).pipe(
      this.setAuthenticated(),
      //prevent double login
      tap(() => {
        this.logoutAdmin();
      })
    );
  }

  sociallogin(userInfo: any) {
    return this.http
      .post<any>(`${this.apiEndPoint}/login/social`, userInfo)
      .pipe(
        this.setAuthenticated(),
        //prevent double login
        tap(() => {
          this.logoutAdmin();
        })
      );
  }

  signupCandidate(userInfo: any) {
    return this.http
      .post<any>(`${this.apiEndPoint}/candidate/signup`, userInfo)
      .pipe(
        this.setAuthenticated(),
        //prevent double login
        tap(() => {
          this.logoutAdmin();
        })
      );
  }

  //signUp and login  with oauth gemi for company requests
  getGemiAuthUrlandRedirectToGemi() {
    this.http
      .get(`${this.apiEndPoint}/oauthGemi/authUrl`)
      .subscribe((res: any) => {
        this.cookieService.set("state", res.state);
        window.location.href = res.authUrl;
      });
  }
  //send the code from gemi to our api to make the oauth process and return as our company user data
  getCompanyDataFromGemi(code) {
    return this.http
      .get<any>(`${this.apiEndPoint}/oauthGemi/oauthProcess?code=${code}`)
      .pipe(
        this.setCompanyAuthenticated(),
        tap(() => {
          this.logoutAdmin();
        })
      );
  }
  //send the code from linkedIn to our api to make the oauth process and return as our candidate user data
  getLinkedInAuthUrlAndRedirectToLinkedIn() {
    return this.http
      .get<any>(`${this.apiEndPoint}/oauthLinkedIn/authUrl`)
      .subscribe((res: any) => {
        this.cookieService.set("linkedInState", res.state);
        window.location.href = res.authUrl;
      });
  }

  getCandidateDataFromLinkedIn(code) {
    return this.http
      .get<any>(`${this.apiEndPoint}/oauthLinkedIn/oauthProcess?code=` + code)
      .pipe(
        this.setAuthenticated(),
        tap(() => {
          this.logoutAdmin();
        })
      );
  }
  //add authorization token to a request
  addAuthHeadersToRequest(request, token) {
    return request.clone({
      headers: request.headers.set("Authorization", "Bearer " + token),
    });
  }
  //send expired access token and refresh token to out api to take a new access token(log in again)
  refreshToken() {
    let body = {
      accessToken: this.getToken(),
      refreshToken: this.getRefreshToken(),
    };
    return this.http
      .post<any>(`${this.apiEndPoint}/login/refreshTokens`, body)
      .pipe(
        tap((refreshedTokens) => {
          if (this.isAdminFunc()) {
            localStorage.setItem("adminToken", refreshedTokens.accessToken);
            localStorage.setItem(
              "adminRefreshToken",
              refreshedTokens.refreshToken
            );
          } else if (this.isCompany() || this.isCandidate()) {
            console.log("refresh token pipe");
            localStorage.setItem("token", refreshedTokens.accessToken);
            localStorage.setItem("refreshToken", refreshedTokens.refreshToken);
          }
        })
      );
  }

  // get token/uuid/context and logout methods
  getCompanyId() {
    // for company
    return localStorage.getItem("id");
  }

  getUUID() {
    // for company/candidate
    return localStorage.getItem("uuid");
  }

  getAdminUUid() {
    return localStorage.getItem("adminUuid");
  }

  isCompany() {
    return localStorage.getItem("context") === "company";
  }

  isCandidate() {
    return localStorage.getItem("context") === "candidate";
  }

  isAdminFunc() {
    return localStorage.getItem("adminContext") === "administrator";
  }

  //user or admin token
  getToken() {
    if (this.isCompany() || this.isCandidate()) {
      return localStorage.getItem("token");
    } else if (this.isAdminFunc()) {
      return localStorage.getItem("adminToken");
    }
  }

  getRefreshToken() {
    if (this.isCompany() || this.isCandidate()) {
      return localStorage.getItem("refreshToken");
    } else if (this.isAdminFunc()) {
      return localStorage.getItem("adminRefreshToken");
    }
  }

  decodeToken(token) {
    try {
      return jwt_decode(token);
    } catch (error) {
      console.log(error);
    }
  }

  logoutCompany() {
    localStorage.removeItem("id");
    localStorage.removeItem("context");
    localStorage.removeItem("social_login");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("lastSuccessfulReqTime");
    this.isValidCompany = false;
    this.isValidCandidate = false;
    this.signedName = "";
    this.authenticated.next(false);
    return;
  }

  logoutCandidateOrCompany() {
    localStorage.removeItem("uuid");
    localStorage.removeItem("context");
    localStorage.removeItem("social_login");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("lastSuccessfulReqTime");
    this.isValidCompany = false;
    this.isValidCandidate = false;
    this.signedName = "";
    this.authenticated.next(false);
    return;
  }

  logoutAdmin() {
    localStorage.removeItem("adminUuid");
    localStorage.removeItem("adminContext");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminRefreshToken");
    localStorage.removeItem("lastSuccessfulReqTime");
    this.adminAuthenticated.next(false);
  }

  AfterUnauthorizedRequestActions() {
    this.logoutCandidateOrCompany();
    this.router.navigate(["/home"]);
    $("#LoginModal").modal("show");
  }

  AfterAdminUnauthorizedRequestActions() {
    this.logoutAdmin();
    this.router.navigate(["/administrator"]);
  }

  AfterUnAuthorizedRequestUiMessage() {
    let statusMessage =
      "H Συνεδρία σας έχει λήξει. Κάντε σύνδεση για να συνεχίσετε";
    let panelStyle = "error-dialog";
    this._snackBar.open(statusMessage, null, {
      duration: 5000,
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: [panelStyle],
    });
  }

  // authed by company requests
  getCompaniesByActivationStatus(
    activationStatus: string,
    currentPage,
    pageSize
  ) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/adminSecure/companies?activationStatus=${activationStatus}` +
        `&page=` +
        currentPage +
        `&size=` +
        pageSize
    );
  }

  getCompanyActivationStatus(id) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/activationStatus` + "?id=" + id
    );
  }

  updateCompaniesActivationStatus(
    companiesUuids: string[],
    activationStatus: string
  ) {
    let body = {
      companiesUuids: companiesUuids,
      updatedStatus: activationStatus,
    };
    return this.http.patch(
      `${this.apiEndPoint}/company/adminSecure/updateCompanyActivationStatus`,
      body,
      { responseType: "text" }
    );
  }

  completeEmployer(employerInfo: any, uuid: any) {
    return this.http.post<any>(
      `${this.apiEndPoint}/company/companySecure/${uuid}/completeProfile`,
      employerInfo
    );
  }

  getJobListByCompany(id, currentPage, pageSize) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/companySecure/` +
        id +
        `/jobposts?size=` +
        pageSize +
        `&page=` +
        currentPage +
        `&sort=creationDate,desc`
    );
  }

  getJobListByCompanyWithFilters(filters: any, currentPage, pageSize, status?) {
    const params = new HttpParams({
      fromObject: Object.entries(filters)
        .filter(([, value]) => value || value === 0)
        .reduce(
          (paramObj, [key, value]) => ({ ...paramObj, [key]: value }),
          {}
        ),
    });

    if (status === "all") {
      return this.http.get<any>(
        `${this.apiEndPoint}/company/companySecure/` +
          localStorage.getItem("id") +
          `/jobposts?size=` +
          pageSize +
          `&page=` +
          currentPage,
        { params: params }
      );
    } else {
      return this.http.get<any>(
        `${this.apiEndPoint}/company/companySecure/` +
          localStorage.getItem("id") +
          `/jobposts?size=` +
          pageSize +
          `&page=` +
          currentPage +
          `&status=` +
          status,
        { params: params }
      );
    }
  }

  getCompanyJobListByStatus(status, currentPage, pageSize) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/companySecure/` +
        localStorage.getItem("id") +
        `/jobposts?size=` +
        pageSize +
        `&page=` +
        currentPage +
        `&sort=creationDate,desc&status=` +
        status
    );
  }

  // option: firstName, creationDate
  // sort: asc, desc
  getCandidatesListWithFiltersAndSorting(
    option,
    sort,
    filters: any,
    currentPage: number,
    pageSize: number
  ) {
    const params = new HttpParams({
      fromObject: Object.entries(filters)
        .filter(([, value]) => value || value === 0)
        .reduce(
          (paramObj, [key, value]) => ({ ...paramObj, [key]: value }),
          {}
        ),
    });

    return this.http.get<any>(
      `${this.apiEndPoint}/candidate/companySecure/all?sort=` +
        option +
        "," +
        sort +
        `&page=` +
        currentPage +
        `&size=` +
        pageSize,
      { params: params }
    );
  }

  getAppliedCandidates(companyUuid, jobPostUuid, sort) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/companySecure/${companyUuid}/${jobPostUuid}/applications?size=100&orderType=` +
        sort
    );
  }

  updateJobStatus(jobPostUuid, status) {
    const formData: FormData = new FormData();
    formData.append("status", status);

    const req = new HttpRequest(
      "PATCH",
      `${this.apiEndPoint}/jobpost/companySecure/${jobPostUuid}/updateStatus`,
      formData,
      { responseType: "text" }
    );
    return this.http.request(req);
  }

  postAJobForm(jobInfo, companyUuid) {
    return this.http.post<any>(
      `${this.apiEndPoint}/jobpost/companySecure/${companyUuid}/create`,
      jobInfo
    );
  }

  updateJobPost(jobPostUuid, jobInfo) {
    return this.http.put<any>(
      `${this.apiEndPoint}/jobpost/companySecure/update/${jobPostUuid}`,
      jobInfo
    );
  }

  deletePostJob(jobPostUuid) {
    return this.http.delete<any>(
      `${this.apiEndPoint}/jobpost/companySecure/delete/${jobPostUuid}`
    );
  }

  //authed by candidate requests

  completeCandidate(userInfo: any, uuid) {
    return this.http.post<any>(
      `${this.apiEndPoint}/candidate/candidateSecure/${uuid}/completeProfile`,
      userInfo
    );
  }

  getCandidateAppliedJobs(sort) {
    return this.http.get<any>(
      `${this.apiEndPoint}/candidate/candidateSecure/` +
        localStorage.getItem("uuid") +
        `/appliedJobs/?size=100&orderType=` +
        sort
    );
  }

  getJobApplicationInfo(jobPostUuid) {
    return this.http.get<any>(
      `${this.apiEndPoint}/jobpost/candidateSecure/` +
        jobPostUuid +
        `/candidate` +
        `/` +
        localStorage.getItem("uuid")
    );
  }

  postApplyJob(applyJob, candidateUuid) {
    return this.http.post<any>(
      `${this.apiEndPoint}/candidate/candidateSecure/${candidateUuid}/applyForJob`,
      applyJob
    );
  }

  getCandidateNotificationsPreferences(candidateUuid: any) {
    return this.http.get(
      `${this.apiEndPoint}/candidate/candidateSecure/${candidateUuid}/notificationsPreferences`
    );
  }

  postNotificationsPreferences(uuid, industiesId, tagsId) {
    return this.http.post(
      `${this.apiEndPoint}/candidate/candidateSecure/${uuid}/updateNotificationsPreferences`,
      { industriesId: industiesId, tagsId: tagsId },
      { responseType: "text" }
    );
  }

  updateNotificationsPreferencesStatus(uuid: any, status: boolean) {
    return this.http.patch(
      `${this.apiEndPoint}/candidate/candidateSecure/${uuid}/updateNotificationsStatus?notificationsStatus=${status}`,
      null,
      { responseType: "text" }
    );
  }

  //admin requests
  loginAdmin(adminInfo) {
    return this.http
      .post<any>(`${this.apiEndPoint}/login/admin`, adminInfo)
      .pipe(
        this.setAdminAuthenticated(),
        //prevent double login
        tap(() => {
          this.logoutCandidateOrCompany();
        })
      );
  }

  getAdminProfile(adminUuid) {
    return this.http.get<any>(`${this.apiEndPoint}/admin/profile/${adminUuid}`);
  }

  createMediatorAdmin(mediatorInfo: any) {
    return this.http.post<any>(
      `${this.apiEndPoint}/admin/signup`,
      mediatorInfo
    );
  }

  updateCandidateStatus(status: any, candidateUuids: any[]) {
    const reqBody = {
      candidateUuids: candidateUuids,
      status: status,
    };
    return this.http.put(
      `${this.apiEndPoint}/candidate/adminSecure/updateStatus`,
      reqBody,
      { responseType: "text" }
    );
  }

  updateJobPostActivationStatus(activationStatus: any, jobPostsUuids: any[]) {
    const reqBody = {
      jobPostsUuids: jobPostsUuids,
      activationStatus: activationStatus,
    };

    return this.http.put(
      `${this.apiEndPoint}/jobpost/updateActivationStatus`,
      reqBody,
      { responseType: "text" }
    );
  }

  // get all job posts by activation status
  // used for admin's job posts handling
  getActiveJobPostsByActivationStatus(
    activationStatus: any,
    currentPage: number,
    pageSize: number
  ) {
    return this.http.get<any>(
      `${this.apiEndPoint}/jobpost/adminSecure/activationStatus` +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage +
        "&sort=creationDate,desc" +
        "&activationStatus=" +
        activationStatus
    );
  }

  //public requests for data
  getCompanyInfo(id) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/completeProfile` + "?id=" + id
    );
  }

  getCompanyPublishedJobs(id, currentPage, pageSize) {
    return this.http.get<any>(
      `${this.apiEndPoint}/company/` +
        id +
        `/published/jobposts?page=` +
        currentPage +
        `&size=` +
        pageSize
    );
  }

  getJobPostsCount() {
    return this.http.get<any>(`${this.apiEndPoint}/jobpost/active/count`);
  }

  getJobPostsPopularLocations() {
    return this.http.get<any>(`${this.apiEndPoint}/jobpost/popularLocations`);
  }

  getMetaData(metadata: any) {
    return this.http.get<any>(`${this.apiEndPoint}/metadata`, metadata);
  }

  jobPostsList(filters: any, currentPage: number, pageSize: number) {
    const params = new HttpParams({
      fromObject: Object.entries(filters)
        .filter(([, value]) => value || value === 0)
        .reduce(
          (paramObj, [key, value]) => ({ ...paramObj, [key]: value }),
          {}
        ),
    });

    return this.http.get<any>(
      `${this.apiEndPoint}/jobpost/active` +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage +
        "&sort=creationDate,desc",
      { params }
    );
  }

  // get jobs by industry and tag
  getRelatedJobs(
    relatedJobs: any,
    industryId: any,
    currentPage: number,
    pageSize: number
  ) {
    return this.http.get<any>(
      `${this.apiEndPoint}/jobpost` +
        "?industryId=" +
        industryId +
        "&page=" +
        currentPage +
        "&size=" +
        pageSize +
        "&sort=updateDate,desc"
    );
  }

  getJobDetailsByuuid(uuid: any) {
    return this.http.get<any>(`${this.apiEndPoint}/jobpost/` + uuid);
  }

  getLogo(context: string, field: string, uuid: any, timestamp: string) {
    const formData: HttpParams = new HttpParams({
      fromObject: { context, uuid, field, timestamp },
    });

    return `${this.apiEndPoint}/file?${formData.toString()}`;
  }

  //companies auth + candidate_searchable = true or specific candidate auth requests
  getCandidateProfile(candidateUuid = undefined) {
    if (candidateUuid === undefined) {
      let loggedInCandidateUuid = this.getUUID();
      return this.http.get<any>(
        `${this.apiEndPoint}/candidate/candidateOrCompanySecure/${loggedInCandidateUuid}/completeProfile`
      );
    } else {
      return this.http.get<any>(
        `${this.apiEndPoint}/candidate/candidateOrCompanySecure/${candidateUuid}/completeProfile`
      );
    }
  }

  //file requests find out where and what kind of auth we need
  uploadApplicationJobCv(file: File, jobPostUuid: any) {
    const formData: FormData = new FormData();
    file ? formData.append("file", file, file.name) : null;
    formData.append("candidateUuid", localStorage.getItem("uuid"));
    formData.append("jobPostUuid", jobPostUuid);

    const req = new HttpRequest(
      "POST",
      `${this.apiEndPoint}/file/applicationCv`,
      formData,
      { responseType: "text" }
    );

    return this.http.request(req);
  }

  downloadCVOfJobApplication(jobuuid) {
    const candidateUuid: any = localStorage.getItem("uuid");
    const jobPostUuid: any = jobuuid;
    const timestamp: any = new Date().getTime().toString();
    const formData: HttpParams = new HttpParams({
      fromObject: { candidateUuid, jobPostUuid, timestamp },
    });

    return `${this.apiEndPoint}/file/applicationCv?${formData.toString()}`;
  }

  downloadCVOfJobApplicationForCompany(jobuuid, candidateuuid) {
    const candidateUuid: any = candidateuuid;
    const jobPostUuid: any = jobuuid;
    const timestamp: any = new Date().getTime().toString();
    const formData: HttpParams = new HttpParams({
      fromObject: { candidateUuid, jobPostUuid, timestamp },
    });

    return `${this.apiEndPoint}/file/applicationCv?${formData.toString()}`;
  }

  postFile(file: File, field: string) {
    let finalId;

    if (localStorage.getItem("id")) finalId = localStorage.getItem("id");
    else finalId = localStorage.getItem("uuid");

    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("uuid", finalId);
    formData.append("field", field);

    const req = new HttpRequest("POST", `${this.apiEndPoint}/file`, formData, {
      responseType: "text",
    });

    return this.http.request(req);
  }

  downloadFile(context: string, field: string, timestamp: string, uuid: any) {
    const formData: HttpParams = new HttpParams({
      fromObject: { context, uuid, field, timestamp },
    });
    return `${this.apiEndPoint}/file?${formData.toString()}`;
  }

  sendEmailForResetPassword(email) {
    return this.http.get(
      `${this.apiEndPoint}/login/resetPasswordEmail?email=${email}`,
      { responseType: "text" }
    );
  }

  sendNewPassword(resetPassToken, candidateUuid, password, confirmPassword) {
    let body = { newPassword: password, reTypedNewPassword: confirmPassword };
    let headers = new HttpHeaders({
      Authorization: "Bearer " + resetPassToken,
    });
    return this.http.post(
      `${this.apiEndPoint}/login/resetPassword/${candidateUuid}`,
      body,
      { headers: headers, responseType: "text" }
    );
  }

  getAllCompanies(gemiNumber, am, name) {
    let params = new HttpParams();
    if (gemiNumber != null) {
      params = params.set("gemiNumber", gemiNumber);
    }
    if (am != null) {
      params = params.set("am", am);
    }
    if (name != null) {
      params = params.set("name", name);
    }
    return this.http.get(`${this.apiEndPoint}/company/all`, { params });
  }

  // // linkedIn oauth requests
  // linkedInAuthAccessToken(code) {
  //   var body = `client_secret=raTMFiSUfkf6mJYx&grant_type=authorization_code&code=` + code + `&redirect_uri=http://localhost:4200/login&client_id=77nu2dnpcj86v0`;
  //   return this.http.post<any>(`https://www.linkedin.com/oauth/v2/accessToken`, body, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
  // }

  // // gets name, lastname
  // linkedInData(accesstoken) {
  //   return this.http.get<any>(`https://api.linkedin.com/v2/me?oauth2_access_token=` + accesstoken);
  // }

  // // gets the email
  // linkedInEmail(accessToken) {
  //   return this.http.get<any>(`https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))&oauth2_access_token=` + accessToken);
  // }

  //not used methods

  // getCompanyActiveJobsWithFilters(filters: any) {
  //   const params = new HttpParams({
  //     fromObject: Object.entries(filters)
  //       .filter(([, value]) => value || value === 0)
  //       .reduce((paramObj, [key, value]) => ({ ...paramObj, [key]: value }), {})
  //   });

  // getCompanyActiveJobs(uuid) {
  //   return this.http.get<any>(`${this.apiEndPoint}/company/` + uuid + `/jobposts/active?size=100&sort=creationDate,desc`);
  // }

  // signupEmployer(userInfo: any) {
  //   return this.http.post<any>(`${this.apiEndPoint}/company/signup`, userInfo)
  //     .pipe(this.setAuthenticated());
  // }

  // getAllJobs() {
  //   return this.http.get<any>(`${this.apiEndPoint}/jobpost/active?size=100&sort=updateDate,desc`);
  // }
}
