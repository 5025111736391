import { BrowserModule } from "@angular/platform-browser";

import {
  NgModule,
  LOCALE_ID,
  ErrorHandler,
  APP_INITIALIZER,
} from "@angular/core";
import { NgbDateCustomParserFormatter } from "./NgbDateCustomParserFormatter";
import {
  NgbDateParserFormatter,
  NgbDate,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelect2Module } from "ng-select2";
import * as bootstrap from "bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./home/home.component";
import { EmployersComponent } from "./employers/employers.component";
import {
  MatSidenavModule,
  MatTabsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatListModule,
  MatSelectModule,
} from "@angular/material";
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { EmployersModule } from "./employers/employers.module";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from "./header/header.component";
import { LoginComponent } from "./header/login/login.component";
import { SignupComponent } from "./header/signup/signup.component";
import { JobListingComponent } from "./job-listing/job-listing.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { SharedModule } from "./shared/shared.module";
import { ProfileResolver } from "./employer-profile/employer-profile.resolver";
import { ProfileComponent } from "./employer-profile/employer-profile.component";
import { CandidateProfileComponent } from "./candidate-profile/candidate-profile.component";
import { CandidatesListComponent } from "./candidates-list/candidates-list.component";
import localeEl from "@angular/common/locales/el";
import { registerLocaleData } from "@angular/common";
import { SignUpModalComponent } from "./sign-up-modal/sign-up-modal.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MessageForLoginOrRegisterModalComponent } from "./message-for-login-or-register-modal/message-for-login-or-register-modal.component";
import { ConstantsService } from "./ConstantsService";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { SafePipe } from "./safe.pipe";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { FaqComponent } from "./faq/faq.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { PromoSignUpComponent } from "./promo-sign-up/promo-sign-up.component";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AuthServiceConfig,
  SocialLoginModule,
} from "angularx-social-login";
import { LoginLinkedInComponent } from "./login-linked-in/login-linked-in.component";
import { OauthGemiForBusinessComponent } from "./oauth-gemi-for-business/oauth-gemi-for-business.component";
import { AdministratorLoginComponent } from "./administrator-login/administrator-login.component";
import { AdministratorCvHandlingComponent } from "./administrator-cv-handling/administrator-cv-handling.component";
import { AdministratorCvHandlingResolver } from "./administrator-cv-handling/administrator-cv-handling.resolver";
import { interceptorProviders } from "./HttpInterceptors/index";
import { AdministratorCreateMediatorsComponent } from "./administrator-create-mediators/administrator-create-mediators.component";
import { AlertMessagesModule } from "./messages-helpers/messages-helpers.module";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { EmailFormForPassChangeComponent } from "./forgot-your-password/email-form-for-pass-change/email-form-for-pass-change.component";
import { ResetPasswordFormComponent } from "./forgot-your-password/reset-password-form/reset-password-form.component";
import { AdministratorHeaderComponent } from "./administrator-header/administrator-header.component";
import { AdministratorFooterComponent } from "./administrator-footer/administrator-footer.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { PaginationModule } from "./pagination/pagination.module";
import { PaginationBsModule } from "./pagination-bs/pagination-bs.module";
import { AdministratorCompanyHandlingComponent } from "./administrator-company-handling/administrator-company-handling.component";
import { AdministratorJobPostHandlingComponent } from "./administrator-job-post-handling/administrator-job-post-handling.component";
// import { AdministratorCompanyHandlingResolver } from './administrator-company-handling/administrator-company-handling.resolver';
//import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
//import { Integrations } from "@sentry/tracing";
import { environment } from "src/environments/environment";

registerLocaleData(localeEl, "el");

/* Sentry.init({
  dsn: "https://f4db1f1fd7a94bb0be52447ae4f1b9e1@sentry.knowledge.gr/22",
}); */

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleId),
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookId),
  },
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    SignupComponent,
    JobListingComponent,
    JobDetailsComponent,
    ProfileComponent,
    CandidateProfileComponent,
    CandidatesListComponent,
    SignUpModalComponent,
    MessageForLoginOrRegisterModalComponent,
    LoginModalComponent,
    SafePipe,
    PrivacyPolicyComponent,
    FaqComponent,
    PromoSignUpComponent,
    LoginLinkedInComponent,
    OauthGemiForBusinessComponent,
    AdministratorLoginComponent,
    AdministratorCvHandlingComponent,
    AdministratorCreateMediatorsComponent,
    AdministratorHeaderComponent,
    AdministratorFooterComponent,
    LoadingSpinnerComponent,
    EmailFormForPassChangeComponent,
    ResetPasswordFormComponent,
    AdministratorJobPostHandlingComponent,
    AdministratorCompanyHandlingComponent,
  ],
  imports: [
    NgSelectModule,
    EditorModule,
    NgbModule,
    HttpClientModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatTabsModule,
    NgSelect2Module,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    EmployersModule,
    SharedModule,
    MatSelectModule,
    NgSelect2Module,
    MatSnackBarModule,
    SocialLoginModule,
    AlertMessagesModule,
    PaginationModule,
    PaginationBsModule,
  ],
  exports: [
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  providers: [
    ConstantsService,
    AdministratorCvHandlingResolver,
    ProfileResolver,
    // AdministratorCompanyHandlingResolver,
    { provide: LOCALE_ID, useValue: "el" },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: AuthServiceConfig, useFactory: provideConfig },
    /* {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }, */
    interceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
